import moment from "moment";
import "moment/locale/es";

export const formatDateHours = (value) => {
    return moment(value)
        .locale("es")
        .utc()
        .format("DD MMMM YYYY HH:mm:ss a");
}

export const formatDate = (value) => {
    return moment(value)
        .locale("es")
        .utc()
        .format("DD MMMM YYYY");
}

export const formatHour = (value) => {
    return moment(value).utc().format("h:mm:ss a");
}

export const formatDateNow = (value) => {
    return moment(value).locale("es").utc().fromNow();
}