<template>
  <v-row class="justify-space-between align-center">
    <v-col cols="12">
      <h2 class="mb-2">Eventos de hoy</h2>
      <v-data-table
        no-data-text="No hay eventos"
        :headers="headersTable"
        :items="getEventsToday"
        sort-by="full_name"
        :loading="loadTable"
        loading-text="Por favor espere..."
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.url_meet="{ item }">
          <a
            class="font-weight-bold"
            :href="item.url_meet"
            target="_blank"
            :style="`color: ${item.color}`"
            >{{ item.url_meet }}</a
          >
        </template>
        <template v-slot:item.date_start="{ item }">
          <p>{{ item.date_start | formatHour }}</p>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            small
            color="grey"
            :to="{ name: 'EventDetail', params: { id: item.id } }"
          >
            <v-icon small> mdi-eye </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatHour } from "../../filters/moment";

export default {
  async created() {
    this.loadTable = true;
    await this.initialize();
    this.loadTable = false;
  },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Dashboard",
      };
    },
  },
  data() {
    return {
      loadTable: false,
      headersTable: [
        { text: "Nombre", value: "event_name" },
        { text: "Url meet", value: "url_meet" },
        { text: "Hora", value: "date_start" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("calendar", ["getErrors", "getEventsToday"]),
  },
  filters: {
    formatHour,
  },

  methods: {
    ...mapActions("calendar", ["getEventsByUser", "setErrors"]),

    async initialize() {
      await this.getEventsByUser();
      this.setErrors("clear");
    },
  },
};
</script>

<style>
</style>
